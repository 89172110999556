import { z } from "zod";

export const RootLoaderDataSchema = z.object({
  baseHomeUrl: z.string(),

  sentryDSN: z.string(),
  sentryEnvironment: z.string(),
  sentryRelease: z.string().optional(),
  sentryUserId: z.string().optional(),
  sentryUsername: z.string().optional(),
});
export type RootLoaderData = z.infer<typeof RootLoaderDataSchema>;
